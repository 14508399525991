import React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

createRoot(document.getElementById("root")).render(<App />);

// https://bit.ly/CRA-PWA
serviceWorker.unregister();
