import config from "./env";
import { authHeader } from "../config/auth-header";
import { handleResponse } from "./responseHandler";

export const categoryService = {
    getAllCategories
};

function getAllCategories() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/category/getAll`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    });
}