export const NavbarItems = [
  {
    title: "Home",
    url: "home",
    variant: "text",
  },
  {
    title: "Mobile App", 
    url: "mobile-app",
    variant: "text",
  },
  {
    title: "How it works",
    url: "services",
    variant: "text",
  },
  {
    title: "About",
    url: "about",
    variant: "text",
  },
  {
    title: "Contact", 
    url: "contact",
    variant: "text",
  },
];
