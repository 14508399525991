import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, {useState, useEffect} from "react";
import {Link} from "react-scroll";
import {NavbarItems} from "./NavbarItems";
import logoIcon from "../../../assets/image/logo-icon.png";
import logoText from "../../../assets/image/logo-text.png";
import useStyles from "./Styles";
import {
  Facebook,
  Instagram,
  LinkedIn,
  WhatsApp,
  YouTube,
} from "@material-ui/icons";

export default function Navbar(props) {
  const {header, menuButton, toolbar, drawerContainer, button} = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const {mobileView, drawerOpen} = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({...prevState, mobileView: true}))
        : setState((prevState) => ({...prevState, mobileView: false}));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>{props.buttonsVisible && getMenuButtons()}</div>
        <div>{getContactButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({...prevState, drawerOpen: true}));
    const handleDrawerClose = () =>
      setState((prevState) => ({...prevState, drawerOpen: false}));

    return (
      <Toolbar>
        {props.buttonsVisible && (
          <IconButton
            {...{
              edge: "start",
              color: "primary",
              style: {color: "primary"},
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {props.buttonsVisible && (
          <Drawer
            {...{
              anchor: "top",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>{getDrawerChoices()}</div>
            <div style={{justifyContent: "center", display: "flex"}}>
              {getContactButtons()}
            </div>
          </Drawer>
        )}

        <div>{femmecubatorLogo}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return NavbarItems.map((item, index) => {
      return (
        <Link
          key={item.title}
          {...{
            to: item.url,
            style: {textDecoration: "none", color: "black"},
            key: item.title,
            offset: -50,
            spy: true,
            smooth: true,
            duration: 1000,
          }}
          onClick={() =>
            setState((prevState) => ({...prevState, drawerOpen: false}))
          }
        >
          <MenuItem style={{textDecoration: "none"}}>{item.title}</MenuItem>
        </Link>
      );
    });
  };

  const femmecubatorLogo = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => window.open("https://localfarmers.in/", "_self")}
    >
      <img src={logoIcon} style={{height: "35px"}} />
      <img src={logoText} style={{height: "20px", marginLeft: "5px"}} />
    </div>
  );

  const getMenuButtons = () => {
    return NavbarItems.map((item) => {
      return (
        <Link
          {...{
            to: item.url,
            color: "inherit",
            style: {textDecoration: "none"},
            key: item.title,
            offset: -50,
            spy: true,
            smooth: true,
            duration: 1000,
          }}
          key={item.title}
        >
          <Button
            {...{
              variant: item.variant,
              className: menuButton,
              size: "large",
            }}
          >
            {item.title}
          </Button>
        </Link>
      );
    });
  };

  const getContactButtons = () => {
    const socialMediaLinks = [
      {
        icon: <Facebook color="primary" />,
        url: "https://www.facebook.com/localfarmers.in/",
      },
      {
        icon: <Instagram color="primary" />,
        url: "https://www.instagram.com/localfarmers.in/",
      },
      {
        icon: <YouTube color="primary" />,
        url: "https://www.youtube.com/channel/UCvjuncuNOgXkuyVK_b7_qYA",
      },
      {
        icon: <LinkedIn color="primary" />,
        url: "https://www.linkedin.com/company/localfarmers-in/",
      },
      {
        icon: <WhatsApp color="primary" />,
        url: "https://wa.me/message/36O3JX4TLOFIK1",
      },
    ];
    return (
      <div>
        {socialMediaLinks.map((item, index) => (
          <IconButton href={item.url} target="_blank" key={index}>
            {item.icon}
          </IconButton>
        ))}
      </div>
    );
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
