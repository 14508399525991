import React from 'react'
import Navbar from '../NavbarComp/Navbar';
import useStyles from './Styles';


const GoogleForm = () => {

    const classes = useStyles();

    return (
        <>
        <Navbar buttonsVisible={false}/>
        <div className={classes.formContainer}>
            <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLScEfBtpsD3fS33lJa2dX2FMgn0EJZ2Kd4INoFKh6EQDgk9vHw/viewform?embedded=true" 
                width="100%" 
                height="100%" 
                style={{position:'absolute'}} 
                frameborder="0" 
                marginheight="0" 
                marginwidth="0">Loading…</iframe>
        </div>
        </>
    )
}

export default GoogleForm
