import config from './env';
import {authHeader} from '../config/auth-header';
import {handleResponse} from './responseHandler';

export const userService = {
  bootLoad,
  createAdmin,
  login,
  logout,
  getAll,
  getById,
  update,
  delete: _delete,
  loadDashboard,
  loadHomePageStats,
  fetchOrder,
  createPaymentLink,
  getStatusForPayment,
};

function bootLoad () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader (),
  };

  return fetch (`${config.apiUrl}/users/bootSetup`, requestOptions)
    .then (handleResponse)
    .then (response => {
      return response;
    });
}

function createAdmin (admin) {
  console.log ('request body');
  console.log (admin);

  const requestOptions = {
    method: 'POST',
    headers: authHeader (),
    body: JSON.stringify (admin),
  };

  return fetch (`${config.apiUrl}/users/createAdmin`, requestOptions)
    .then (handleResponse)
    .then (response => {
      console.log (response);
      return response;
    });
}

function login (username, password) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader (),
    body: JSON.stringify ({username, password}),
  };

  return fetch (`${config.apiUrl}/users/authenticateAdmin`, requestOptions)
    .then (handleResponse)
    .then (user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem ('user', JSON.stringify (user));

      return user;
    });
}

function loadDashboard () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader (),
  };

  return fetch (`${config.apiUrl}/users/getSiteInfo`, requestOptions)
    .then (handleResponse)
    .then (response => {
      return response;
    });
}

function loadHomePageStats () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader (),
  };

  return fetch (`${config.apiUrl}/users/getDashboardInfo`, requestOptions)
    .then (handleResponse)
    .then (response => {
      return response;
    });
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem ('user');
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader (),
  };

  return fetch (`${config.apiUrl}/users`, requestOptions).then (handleResponse);
}

function getById (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader (),
  };

  return fetch (`${config.apiUrl}/users/${id}`, requestOptions).then (
    handleResponse
  );
}

function update (user) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader (), 'Content-Type': 'application/json'},
    body: JSON.stringify (user),
  };

  return fetch (`${config.apiUrl}/users/${user.id}`, requestOptions).then (
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete (id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader (),
  };

  return fetch (`${config.apiUrl}/users/${id}`, requestOptions).then (
    handleResponse
  );
}

function fetchOrder (orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader (),
  };

  return fetch (`${config.apiUrl}/order/public/${orderId}`, requestOptions)
    .then (handleResponse)
    .then (response => {
      return response;
    });
}

function createPaymentLink (param) {
  param.environmentBase = config.apiUrl;
  const requestOptions = {
    method: 'POST',
    headers: authHeader (),
    body: JSON.stringify (param),
  };

  return fetch (`${config.apiUrl}/order/createPaymentLink`, requestOptions)
    .then (handleResponse)
    .then (response => {
      return response;
    });
}

function getStatusForPayment (paymentId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader (),
  };

  let path = `${config.apiUrl}/order/getStatusForPayment?paymentId=${paymentId}`;
  return fetch (path, requestOptions).then (handleResponse).then (response => {
    return response;
  });
}
