import { makeStyles } from '@material-ui/core/styles';
import formImage from '../../../assets/image/google-form-image.jpg'

const useStyles = makeStyles((theme) => ({
    formContainer:{
      backgroundImage: `url(${formImage})`,
      backgroundSize: '100vw 100vh',
      backgroundRepeat:'no-repeat',
      minHeight:'100vh',
      marginTop:'50px'
    }
  }));

  export default useStyles