export const BootSetup = {
  BOOT_LOADED: "BOOT_LOADED",
  BOOT_FAILED: "BOOT_FAILED"
};

export const AlertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR"
};

export const UserConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  CLEAR_ERROR: "CLEAR_USER_ERROR",

  LOGOUT: "USERS_LOGOUT"
};

export const CategoryConstants = {
  FETCH_CATEGORIES_REQUEST: "FETCH_CATEGORIES_REQUEST",
  FETCH_CATEGORIES_SUCCESS: "FETCH_CATEGORIES_SUCCESS",
  FETCH_CATEGORIES_FAIL: "FETCH_CATEGORIES_FAIL"
};
