const env = "prod";
const config = {
  test: {
    apiUrl: "https://developapi.localshops.in",
    siteName: "LocalFarmers",
    facebookUrl: "https://www.facebook.com/localfarmers.in/",
    whatsAppUrl: "https://wa.me/919008242363",
    isProd: false,
  },
  prod: {
    apiUrl: "https://api.localfarmers.in",
    siteName: "LocalFarmers",
    facebookUrl: "https://www.facebook.com/localfarmers.in/",
    whatsAppUrl: "https://wa.me/919008242363",
    isProd: true,
  },
  local: {
    apiUrl: "http://localhost:3100",
    siteName: "LocalFarmers",
    facebookUrl: "https://www.facebook.com/localfarmers.in/",
    whatsAppUrl: "https://wa.me/919008242363",
    isProd: false,
  },
};

module.exports = {
  version: 0,
  apiUrl: config[env].apiUrl,
  siteName: config[env].siteName,
  facebookUrl: config[env].facebookUrl,
  whatsAppUrl: config[env].whatsAppUrl,
  isProd: config[env].isProd,
};
