import React from "react";
import {connect} from "react-redux";
import {Route, Routes} from "react-router-dom";

import {setupActions} from "../businessLogic/actions";

import LinearProgress from "@material-ui/core/LinearProgress";
import {withStyles} from "@material-ui/styles";
import GoogleForm from "./LandingContainer/GoogleForm/GoogleForm";
import PrivacyPolicyPage from "./LandingContainer/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionsPage from "./LandingContainer/TermsAndConditionsPage/TermsAndConditionsPage";

const Order = React.lazy(() => import("./LandingContainer/Order/Order"));
const PaymentStatus = React.lazy(() =>
  import("./LandingContainer/PaymentStatus/PaymentStatus")
);
const AppContainer = React.lazy(() => import("./AppContainer"));

export const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.secondary,
    padding: theme.spacing(0),
    color: "red",
  },
});

class MainRoot extends React.Component {
  componentDidMount() {
    this.props.loadBoot();
  }

  render() {
    if (this.props.user.isBootLoaded == null) {
      return <LinearProgress />;
    }

    if (this.props.user.isBootLoaded === false) {
      return (
        <div className={this.props.classes.root}>
          We have some problem in loading page. Please try after some time.
        </div>
      );
    }

    if (this.props.user.isAdminAvailable === false) {
      return (
        <div className={this.props.classes.root}>
          This site under development, please try after some time.
        </div>
      );
    }

    return (
      <React.Suspense fallback={<p>Please wait</p>}>
        <Routes>
          <Route path="/" exact element={<AppContainer />} />
          <Route path="/Order/:orderId" element={<Order />} />
          <Route
            path="/Status/:paymentId"
            element={<PaymentStatus history={this.props.history} />}
          />
          <Route path="/Connect" element={<GoogleForm />} />
          <Route path="/PrivacyPolicy" exact element={<PrivacyPolicyPage />} />
          <Route
            path="/Terms&Conditions"
            exact
            element={<TermsAndConditionsPage />}
          />
        </Routes>
      </React.Suspense>
    );
  }
}

const mapDispatchToProps = {
  loadBoot: setupActions.loadBoot,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(MainRoot));
