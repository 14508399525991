import { BootSetup } from "./types";
import { userService } from "../services";

export const setupActions = {
  loadBoot
};

function loadBoot() {
  return dispatch => {
    userService.bootLoad().then(
      info => {
        dispatch(requestSuccess(info));
      },
      error => {
        dispatch(requestError(error.toString()));
      }
    );

    function requestSuccess(info) {
      return { type: BootSetup.BOOT_LOADED, payload: info };
    }

    function requestError(error) {
      return { type: BootSetup.BOOT_FAILED, payload: error };
    }
  };
}
