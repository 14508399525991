import { Container, Typography } from '@material-ui/core'
import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../NavbarComp/Navbar'

const PrivacyPolicyPage = () => {
    return (
        <>
        <Navbar buttonsVisible={false}/>
        <Container style={{marginTop:100,display:'flex',flexDirection:'column'}}>
            <Typography variant="h3" color="primary" style={{alignSelf:'center',marginBottom:"30px",textDecorationLine:'underline'}}>
            Privacy Policy
            </Typography>
            <Typography variant="h5" color="primary" >
            Personal Information
            </Typography>
            <Typography>
            Sanjivini Eco Solutions is the licensed owner of the brand
            LocalFarmers and the website localfarmers.in. Sanjivini Eco Solutions
            respects your privacy. This Privacy Policy provides succinctly the
            manner your data is collected and used by Sanjivini Eco Solutions on
            the Site. As a visitor to the Site/ Customer you are advised to please
            read the Privacy Policy carefully. By accessing the services provided
            by the Site you agree to the collection and use of your data by
            Sanjivini Eco Solutions in the manner provided in this Privacy Policy.
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="primary" >
            What information is, or may be, collected form you?
            </Typography>
            <Typography>
            As part of the registration process on the Site, Sanjivini Eco
            Solutions may collect the following personally identifiable
            information about you: Name including first and last name, alternate
            email address, mobile phone number and contact details, Postal code,
            Demographic profile (like your age, gender, occupation, education,
            address etc.) and information about the pages on the site you
            visit/access, the links you click on the site, the number of times you
            access the page and any such browsing information.
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="primary" >
            How do we Collect the Information ?
            </Typography>
            <Typography>
            Sanjivini Eco Solutions will collect personally identifiable
            information about you only as part of a voluntary registration
            process, on-line survey or any combination thereof. The Site may
            contain links to other Web sites. Sanjivini Eco Solutions is not
            responsible for the privacy practices of such Web sites which it does
            not own, manage or control.{' '}
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="primary" >
            How is information used ?
            </Typography>
            <Typography>
            Sanjivini Eco Solutions will use your personal information to provide
            personalized features to you on the Site and to provide for
            promotional offers to you through the Site and other channels.
            Sanjivini Eco Solutions will also provide this information to its
            business associates and partners to get in touch with you when
            necessary to provide the services requested by you. Sanjivini Eco
            Solutions will use this information to preserve transaction history as
            governed by existing law or policy. Sanjivini Eco Solutions may also
            use contact information internally to direct its efforts for product
            improvement, to contact you as a survey respondent, to notify you if
            you win any contest; and to send you promotional materials from its
            contest sponsors or advertisers. Information about Customers on an
            aggregate (exlcuding any information that may identify you
            specifically) covering Customer transaction data and Customer
            demographic and location data may be provided to partners of Sanjivini
            Eco Solutions for the purpose of creating additional features on the
            website, creating appropriate merchandising or creating new products
            and services and conducting marketing research and statistical
            analysis of customer behaviour and transactions.
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="primary" >
            With whom your information will be shared
            </Typography>
            <Typography>
            Sanjivini Eco Solutions will not use your financial information for
            any purpose other than to complete a transaction with you. Sanjivini
            Eco Solutions does not rent, sell or share your personal information
            and will not disclose any of your personally identifiable information
            to third parties. In cases where it has your permission to provide
            products or services you've requested and such information is
            necessary to provide these products or services the information may be
            shared with Sanjivini Eco Solutions’s business associates and
            partners. Sanjivini Eco Solutions may, however, share consumer
            information on an aggregate with its partners or thrird parties where
            it deems necessary. In addition Sanjivini Eco Solutions may use this
            information for promotional offers, to help investigate, prevent or
            take action regarding unlawful and illegal activities, suspected
            fraud, potential threat to the safety or security of any person,
            violations of the Site’s terms of use or to defend against legal
            claims; special circumstances such as compliance with subpoenas, court
            orders, requests/order from legal authorities or law enforcement
            agencies requiring such disclosure.
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="primary" >
            What Choice are available to you regarding collection, use and
            distribution of your information ?
            </Typography>
            <Typography>
            To protect against the loss, misuse and alteration of the information
            under its control, Sanjivini Eco Solutions has in place appropriate
            physical, electronic and managerial procedures. For example, Sanjivini
            Eco Solutions servers are accessible only to authorized personnel and
            your information is shared with employees and authorised personnel on
            a need to know basis to complete the transaction and to provide the
            services requested by you. Although Sanjivini Eco Solutions will
            endeavour to safeguard the confidentiality of your personally
            identifiable information, transmissions made by means of the Internet
            cannot be made absolutely secure. By using this site, you agree that
            Sanjivini Eco Solutions will have no liability for disclosure of your
            information due to errors in transmission or unauthorized acts of
            third parties.
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="primary" >
            How can you correct inaccuracies in the information ?
            </Typography>
            <Typography>
            To correct or update any information you have provided, the Site
            allows you to do it online. In the event of loss of access details you
            can send an e-mail to: support@localfarmers.in
            </Typography>
            <br />
            <br />
            <Typography variant="h5" color="primary" >
            Policy updates
            </Typography>
            <Typography>
            Sanjivini Eco Solutions reserves the right to change or update this
            policy at any time. Such changes shall be effective immediately upon
            posting to the Site.
            </Typography>
            <br />
        </Container>
        <Footer/>
      </>
    )
}

export default PrivacyPolicyPage
