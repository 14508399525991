import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "white",
    paddingRight: "50px",
    //paddingBottom:theme.spacing(1),
    boxShadow: "none",
    paddingLeft: "55px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    color: "black",
    textAlign: "left",
  },
  menuButton: {
    fontWeight: 600,
    size: "18px",
    marginLeft: "20px",
    textTransform: "none",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export default useStyles;
