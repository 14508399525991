import {createTheme} from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#5CAA4D",
    },
    secondary: {
      main: "#F0FFED",
    },
    error: {
      main: "#D13333",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});

export default theme;
