/**
 * LocalFarmers
 *
 * @format
 * @flow
 */

import { combineReducers } from "redux";
import user from "./user";
import category from "./category";

const reducers = combineReducers({
  user,
  category
});

export default reducers;
