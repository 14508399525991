export const handleResponse = handleServerResponse;

function handleServerResponse (response) {
  return response.text ().then (text => {
    const data = JSON.parse (text);
    if (!response.ok) {
      //  return Promise.reject(data.debugInfo);
      throw data.debugInfo;
    }

    return data.result;
  });
}
