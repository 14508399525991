import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

import configureStore from "./businessLogic/config/store";
import theme from "./businessLogic/config/theme";
import MainRoot from "./containers/MainRoot";

import {ThemeProvider} from "@material-ui/core/styles";

function App() {
  return (
    <Provider store={configureStore().store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <MainRoot />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
