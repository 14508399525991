import {Box, Container, Typography, Link, IconButton} from "@material-ui/core";
import React from "react";
import useStyles from "./Styles";
import {
  Facebook,
  Instagram,
  LinkedIn,
  WhatsApp,
  YouTube,
} from "@material-ui/icons";

function Copyright() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 10,
        }}
      >
        <Link href="/PrivacyPolicy">
          <Typography
            variant="subtitle2"
            color="primary"
            align="center"
            style={{cursor: "pointer", marginRight: 5}}
          >
            Privacy Policy
          </Typography>
        </Link>
        <Typography
          variant="subtitle2"
          color="primary"
          align="center"
          style={{fontWeight: "500"}}
        >
          &
        </Typography>
        <Link href="/Terms&Conditions">
          <Typography
            variant="subtitle2"
            color="primary"
            align="center"
            style={{cursor: "pointer", marginLeft: 5}}
          >
            Terms and Conditions
          </Typography>
        </Link>
      </div>

      <Typography
        variant="subtitle1"
        align="center"
        style={{fontWeight: "600", color: "black"}}
      >
        Initiative by Sanjivini Eco Solutions Pvt. Ltd
      </Typography>
    </>
  );
}

const getContactButtons = () => {
  const socialMediaLinks = [
    {
      icon: <Facebook color="primary" fontSize="large" />,
      url: "https://www.facebook.com/localfarmers.in/",
    },
    {
      icon: <Instagram color="primary" fontSize="large" />,
      url: "https://www.instagram.com/localfarmers.in/",
    },
    {
      icon: <YouTube color="primary" fontSize="large" />,
      url: "https://www.youtube.com/channel/UCvjuncuNOgXkuyVK_b7_qYA",
    },
    {
      icon: <LinkedIn color="primary" fontSize="large" />,
      url: "https://www.linkedin.com/company/localfarmers-in/",
    },
    {
      icon: <WhatsApp color="primary" />,
      url: "https://wa.me/message/36O3JX4TLOFIK1",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      {socialMediaLinks.map((item, index) => (
        <IconButton href={item.url} target="_blank" key={index}>
          {item.icon}
        </IconButton>
      ))}
    </div>
  );
};

export default function Footer() {
  const classes = useStyles();
  return (
    <Container maxWidth="md" component="footer" className={classes.footer}>
      <Box mt={5}>{getContactButtons()}</Box>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}
